export enum ProjectType {
  customHome = 'custom-home',
  hotelResort = 'hotel-resort',
  winery = 'winery',
  restaurant = 'restaurant',
  historic = 'historic',
  community = 'community',
  officeWarehouse = 'office-warehouse',
  industrial = 'industrial'
}

export enum SidebarType {
  category = 'category',
  location = 'location'
}

export interface ProjectSideBar {
  id: SidebarType;
  name: string;
  value: string;
}

export interface ProjectImage {
  path: string;
  alt: string;
}

export interface ProjectCollaboration {
  image: ProjectImage;
  subtitle: string;
  text: string;
  title: string;
}

export interface ProjectContent {
  collaborations: ProjectCollaboration[];
  images: ProjectImage[];
  paragraphs: string[];
  sidebar: ProjectSideBar[];
  title: string;
}

export interface Project {
  id: string;
  category: string;
  content: ProjectContent;
  friendlyName: string;
  slug: string;
  teaser: any[];
  type: ProjectType;
  order: number;
}
