import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {firestore} from 'firebase';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  deployDisabled = false;

  constructor(private afs: AngularFirestore, private authService: AuthService) {
  }

  ngOnInit() {
    this.deployDisabled = false;
  }

  deploy() {
    this.deployDisabled = true;
    console.log('Deploying!');
    this.afs.collection<any>('deployment').add({created: firestore.Timestamp.now()})
      .then(() => {
        this.deployDisabled = false;
        return alert('Deployment has been initiated. This action may take several minutes to complete');
      })
      .catch((error) => {
        this.deployDisabled = false;
        console.error(error);
        return alert('Something went wrong. Please wait and try again later. If the issue persists contact support.');
      });
  }

  logout() {
    this.authService.logout()
      .then(() => {
        console.log('Successfully Logged Out!');
      });
  }
}
