import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private returnUrl: string;
  signupForm: FormGroup;
  authStatus = {
    isError: null,
    authData: {
      code: null,
      message: null
    }
  };

  constructor(public fb: FormBuilder, public auth: AuthService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/home/landing';
    this.signupForm = this.fb.group({
      email: ['',
        [
          Validators.required,
          Validators.email
        ]
      ],
      password: ['',
        [
          Validators.minLength(6),
          Validators.maxLength(25),
          Validators.required
        ]
      ]
    });

    this.auth.isLoggedIn
      .then((user) => {
        if (!!user) {
          return this.navigateToNextPage();
        }
      });
  }

  // Using getters will make your code look pretty
  get email() {
    return this.signupForm.get('email');
  }

  get password() {
    return this.signupForm.get('password');
  }

  // Step 1
  login(): Promise<boolean> {
    return this.auth.emailLogin(this.email.value, this.password.value)
      .then((data) => {
        if (!!data) {
          this.authStatus.isError = true;
          this.authStatus.authData = data;
        }
        return this.navigateToNextPage();
      })
  }

  private navigateToNextPage(): Promise<boolean> {
    if (!!this.returnUrl) {
      return this.router.navigate([this.returnUrl]);
    }
    return this.router.navigate(['/home', 'landing']);
  }
}
