import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {NewsArticle} from '../models/news-article.model';
import {firestore} from 'firebase';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements AfterViewInit {
  displayedColumns: string[] = ['order', 'publication', 'title', 'subtitle', 'date', 'edit'];
  dataSource: MatTableDataSource<NewsArticle>;

  newsCollectionRef: AngularFirestoreCollection<NewsArticle>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private afs: AngularFirestore) {
  }

  ngAfterViewInit() {
    this.newsCollectionRef = this.afs.collection<NewsArticle>('news');

    this.newsCollectionRef.snapshotChanges().pipe(
      map((actions) => {
        let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as NewsArticle;
          if (!data.order) {
            data.order = initialOrder;
          }
          const id = a.payload.doc.id;
          initialOrder++;
          return {id, ...data};
        });
      })
    ).subscribe(data => {
      if (this.dataSource) {
        this.dataSource.data = data;
      } else {
        this.dataSource = new MatTableDataSource(data);
      }
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  orderUp(item: NewsArticle) {
    const sorted = this.dataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemAbove = sorted[index - 1];

    if (index > 0) {
      const itemDoc = this.afs.doc<NewsArticle>(`news/${item.id}`);
      const itemAboveDoc = this.afs.doc<NewsArticle>(`news/${itemAbove.id}`);
      itemDoc.update({order: item.order - 1});
      itemAboveDoc.update({order: itemAbove.order + 1});
    }
  }

  orderDown(item: NewsArticle) {
    const sorted = this.dataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemBelow = sorted[index + 1];

    if (index < this.dataSource.data.length) {
      const itemDoc = this.afs.doc<NewsArticle>(`news/${item.id}`);
      const itemBelowDoc = this.afs.doc<NewsArticle>(`news/${itemBelow.id}`);
      itemDoc.update({order: item.order + 1});
      itemBelowDoc.update({order: itemBelow.order - 1});
    }
  }

  trackByUid(index, item) {
    return item.uid;
  }

  publicationDate(timestamp: firestore.Timestamp): Date {
    return timestamp.toDate();
  }
}
