import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {firestore} from 'firebase';
import {Team, TeamSocial} from '../models/team.model';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit, OnDestroy {
  memberRef: AngularFirestoreDocument<Team>;
  memberCollectionRef: AngularFirestoreCollection<Team>;
  member: Team;

  socialIcons = {
    facebook: {
      name: 'Facebook',
      slug: 'facebook',
      icon: 'fab fa-facebook-f'
    },
    linkedin: {
      name: 'LinkedIn',
      slug: 'linkedin',
      icon: 'fab fa-linkedin-in'
    },
    twitter: {
      name: 'Twitter',
      slug: 'twitter',
      icon: 'fab fa-twitter'
    },
    instagram: {
      name: 'Instagram',
      slug: 'instagram',
      icon: 'fab fa-instagram'
    }
  };

  private sub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.memberCollectionRef = this.afs.collection<Team>('experts');
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params.id && params.id !== 'new') {
        this.memberRef = this.memberCollectionRef.doc(params.id);

        this.memberRef.get().subscribe((documentSnapshot: firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            this.member = {
              id: documentSnapshot.id,
              about: data.about,
              social: data.social,
              image: data.image,
              lower: data.lower,
              title: data.title,
              name: data.name,
              order: data.order
            };
          } else {
            this.router.navigate(['/not-found']);
          }
        });
      } else {
        this.member = {
          id: null,
          social: {
            icon: null,
            id: null,
            path: null
          },
          about: null,
          image: null,
          lower: [],
          title: null,
          name: null,
          order: 0
        };
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateSocialInfo(selectedItem: string) {
    this.member.social.icon = this.socialIcons[selectedItem].icon;
  }

  updateLowerSocialInfo(selectedItem: string, item: TeamSocial) {
    item.icon = this.socialIcons[selectedItem].icon;
  }

  addLower() {
    this.member.lower.push({
      id: null,
      icon: null,
      path: null
    });
  }

  deleteLower(index) {
    this.member.lower.splice(index, 1);
  }

  deleteMember() {
    const confirmDelete = confirm('This action is irreversible. Are you sure you want to delete the member?');
    if (confirmDelete) {
      this.memberCollectionRef.doc(this.member.id).delete()
        .then(() => {
          return this.router.navigate(['/home/expertise']);
        });
    }
  }

  saveMember() {
    delete this.member.id;

    if (!this.memberRef) {
      this.memberCollectionRef.add(this.member)
        .then(() => {
          return this.router.navigate(['/home/expertise']);
        });
    } else {
      return this.memberRef.update(this.member)
        .then(() => {
          return this.router.navigate(['/home/expertise']);
        });
    }
  }
}

