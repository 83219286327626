import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {firestore} from 'firebase';
import {LandingProject} from '../models/landing-project';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit, OnDestroy {
  projectCardRef: AngularFirestoreDocument<LandingProject>;
  projectCardCollectionRef: AngularFirestoreCollection<LandingProject>;
  projectCard: LandingProject;

  private sub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.projectCardCollectionRef = this.afs.collection<LandingProject>('landing-projects');
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params.id && params.id !== 'new') {
        this.projectCardRef = this.projectCardCollectionRef.doc(params.id);

        this.projectCardRef.get().subscribe((documentSnapshot: firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            this.projectCard = {
              id: documentSnapshot.id,
              imageUrl: data.imageUrl,
              alt: data.alt,
              name: data.name,
              link: data.link,
              order: data.order
            };
          } else {
            this.router.navigate(['/not-found']);
          }
        });
      } else {
        this.projectCard = {
          id: null,
          imageUrl: null,
          alt: null,
          name: null,
          link: null,
          order: null
        };
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveProjectCard() {
    delete this.projectCard.id;

    if (!this.projectCardRef) {
      this.projectCardCollectionRef.add(this.projectCard)
        .then(() => {
          return this.router.navigate(['/home/landing']);
        });
    } else {
      return this.projectCardRef.update(this.projectCard)
        .then(() => {
          return this.router.navigate(['/home/landing']);
        });
    }
  }
}
