import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import UserCredential = firebase.auth.UserCredential;

// custom user interface
interface User {
  uid: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          // logged in, get custom user from Firestore
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  get isLoggedIn(): Promise<any> {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((user: UserCredential) => {
        return this.setUserDoc(user);
      })
      .catch(error => this.handleError(error));
  }

  logout(): Promise<void> {
    return this.afAuth.auth.signOut()
      .then(() => {
        this.router.navigate(['/login']);
      });
  }

  // If error, console log and notify user
  private handleError(error) {
    console.error(error);
    return error;
  }

  // Sets user data to firestore after successful login
  private setUserDoc(user: UserCredential) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.user.uid}`);
    const data: User = {
      uid: user.user.uid,
      email: user.user.email || null
    };
    return userRef.update(data);
  }
}
