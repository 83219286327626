import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Service} from '../models/service.model';
import {OtherTeam, Team} from '../models/team.model';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.css']
})
export class ExpertiseComponent implements AfterViewInit {
  servicesDisplayedColumns: string[] = ['order', 'title', 'edit'];
  teamDisplayedColumns: string[] = ['order', 'name', 'title', 'edit'];
  otherTeamDisplayedColumns: string[] = ['order', 'name', 'title', 'edit'];

  servicesDataSource: MatTableDataSource<Service>;
  teamDataSource: MatTableDataSource<Team>;
  otherTeamDataSource: MatTableDataSource<OtherTeam>;

  servicesRef: AngularFirestoreCollection<Service>;
  teamRef: AngularFirestoreCollection<Team>;
  otherTeamRef: AngularFirestoreCollection<OtherTeam>;

  @ViewChild('serviceSort', {static: true}) serviceSort: MatSort;
  @ViewChild('memberSort', {static: true}) memberSort: MatSort;
  @ViewChild('otherMemberSort', {static: true}) otherMemberSort: MatSort;

  constructor(private afs: AngularFirestore) {
  }

  ngAfterViewInit() {
    this.servicesRef = this.afs.collection<Service>('services');
    this.servicesRef.snapshotChanges().pipe(
      map((actions) => {
        let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as Service;
          if (!data.order) {
            data.order = initialOrder;
          }
          const id = a.payload.doc.id;
          initialOrder++;
          return {id, ...data};
        });
      })
    ).subscribe(data => {
      if (this.servicesDataSource) {
        this.servicesDataSource.data = data;
      } else {
        this.servicesDataSource = new MatTableDataSource(data);
      }
      this.servicesDataSource.sort = this.serviceSort;
    });

    this.teamRef = this.afs.collection<Team>('experts');
    this.teamRef.snapshotChanges().pipe(
      map((actions) => {
        let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as Team;
          if (!data.order) {
            data.order = initialOrder;
          }
          const id = a.payload.doc.id;
          initialOrder++;
          return {id, ...data};
        });
      })
    ).subscribe(data => {
      if (this.teamDataSource) {
        this.teamDataSource.data = data;
      } else {
        this.teamDataSource = new MatTableDataSource(data);
      }
      this.teamDataSource.sort = this.memberSort;
    });

    this.otherTeamRef = this.afs.collection<OtherTeam>('other-experts');
    this.otherTeamRef.snapshotChanges().pipe(
      map((actions) => {
        let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as OtherTeam;
          if (!data.order) {
            data.order = initialOrder;
          }
          const id = a.payload.doc.id;
          initialOrder++;
          return {id, ...data};
        });
      })
    ).subscribe(data => {
      if (this.otherTeamDataSource) {
        this.otherTeamDataSource.data = data;
      } else {
        this.otherTeamDataSource = new MatTableDataSource(data);
      }
      this.otherTeamDataSource.sort = this.otherMemberSort;
    });
  }

  trackByUid(index, item) {
    return item.uid;
  }


  orderServiceUp(item: Service) {
    const sorted = this.servicesDataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemAbove = sorted[index - 1];

    if (index > 0) {
      const itemDoc = this.afs.doc<any>(`services/${item.id}`);
      const itemAboveDoc = this.afs.doc<any>(`services/${itemAbove.id}`);
      itemDoc.update({order: item.order - 1});
      itemAboveDoc.update({order: itemAbove.order + 1});
    }
  }

  orderServiceDown(item: Service) {
    const sorted = this.servicesDataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemBelow = sorted[index + 1];

    if (index < this.servicesDataSource.data.length) {
      const itemDoc = this.afs.doc<any>(`services/${item.id}`);
      const itemBelowDoc = this.afs.doc<any>(`services/${itemBelow.id}`);
      itemDoc.update({order: item.order + 1});
      itemBelowDoc.update({order: itemBelow.order - 1});
    }
  }

  orderMemberUp(item: Team) {
    const sorted = this.teamDataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemAbove = sorted[index - 1];

    if (index > 0) {
      const itemDoc = this.afs.doc<any>(`experts/${item.id}`);
      const itemAboveDoc = this.afs.doc<any>(`experts/${itemAbove.id}`);
      itemDoc.update({order: item.order - 1});
      itemAboveDoc.update({order: itemAbove.order + 1});
    }
  }

  orderMemberDown(item: Team) {
    const sorted = this.teamDataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemBelow = sorted[index + 1];

    if (index < this.servicesDataSource.data.length) {
      const itemDoc = this.afs.doc<any>(`experts/${item.id}`);
      const itemBelowDoc = this.afs.doc<any>(`experts/${itemBelow.id}`);
      itemDoc.update({order: item.order + 1});
      itemBelowDoc.update({order: itemBelow.order - 1});
    }
  }

  orderOtherMemberUp(item: OtherTeam) {
    const sorted = this.otherTeamDataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemAbove = sorted[index - 1];

    if (index > 0) {
      const itemDoc = this.afs.doc<any>(`experts/${item.id}`);
      const itemAboveDoc = this.afs.doc<any>(`experts/${itemAbove.id}`);
      itemDoc.update({order: item.order - 1});
      itemAboveDoc.update({order: itemAbove.order + 1});
    }
  }

  orderOtherMemberDown(item: OtherTeam) {
    const sorted = this.otherTeamDataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemBelow = sorted[index + 1];

    if (index < this.servicesDataSource.data.length) {
      const itemDoc = this.afs.doc<any>(`other-experts/${item.id}`);
      const itemBelowDoc = this.afs.doc<any>(`other-experts/${itemBelow.id}`);
      itemDoc.update({order: item.order + 1});
      itemBelowDoc.update({order: itemBelow.order - 1});
    }
  }
}
