import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {CommonModule} from '@angular/common';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {MatButtonModule, MatFormFieldModule, MatInputModule} from '@angular/material';
// import {AngularFireStorageModule} from '@angular/fire/storage';

// Environment
import {environment} from 'src/environments/environment';
// Authentication
import {AuthService} from './services/auth.service';
import {AuthGuard} from './guards/auth-guard.guard';
// Internal Components
import {LoginComponent} from './login/login.component';
import {NotFoundComponent} from './not-found/not-found.component';
// Internal Modules
import {DirectivesModule} from './directives/directives.module';
import {MainModule} from './main/main.module';
// App Routing
import {AppRoutingModule} from './app-routing.module';

@NgModule({
  imports: [
    DirectivesModule,
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    // 3. Initialize
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    // AngularFireStorageModule, // storage
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MainModule,
    AppRoutingModule
  ],
  declarations: [AppComponent, LoginComponent, NotFoundComponent],
  bootstrap: [AppComponent],
  providers: [AuthService, AuthGuard]
})
export class AppModule {
}
