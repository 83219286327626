import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {firestore} from 'firebase';
import {remove} from 'lodash';
import {Project, ProjectType} from '../models/project.model';

@Component({
  selector: 'app-community-project',
  templateUrl: './community-project.component.html',
  styleUrls: ['./community-project.component.css']
})
export class CommunityProjectComponent implements OnInit, OnDestroy {
  projectRef: AngularFirestoreDocument<Project>;
  projectsCollectionRef: AngularFirestoreCollection<Project>;
  project: Project;

  private sub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.projectsCollectionRef = this.afs.collection<Project>('projects');
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params.id && params.id !== 'new') {
        this.projectRef = this.projectsCollectionRef.doc(params.id);

        this.projectRef.get().subscribe((documentSnapshot: firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            this.project = {
              id: documentSnapshot.id,
              category: data.category,
              content: data.content,
              friendlyName: data.friendlyName,
              slug: data.slug,
              teaser: data.teaser,
              type: data.type,
              order: data.order
            };
          } else {
            this.router.navigate(['/not-found']);
          }
        });
      } else {
        this.project = {
          id: null,
          category: null,
          content: {
            collaborations: [],
            images: [],
            paragraphs: [],
            sidebar: [],
            title: null
          },
          friendlyName: null,
          slug: null,
          teaser: [],
          type: ProjectType.community,
          order: 1
        };
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteProject() {
    this.projectsCollectionRef.doc(this.project.id).delete()
      .then(() => {
        return this.router.navigate(['/home/community']);
      });
  }

  saveProject(newProject: Project) {
    // Ensure this is true so we don't lose track of the projects.
    this.project.category = 'community-buildings';
    this.project.type = ProjectType.community;

    if (!this.projectRef) {
      this.projectsCollectionRef.add(newProject)
        .then(() => {
          return this.router.navigate(['/home/community']);
        });
    } else {
      return this.projectRef.update(newProject)
        .then(() => {
          return this.router.navigate(['/home/community']);
        });
    }
  }
}
