import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Project, ProjectType} from '../models/project.model';

@Component({
  selector: 'app-wineries',
  templateUrl: './wineries.component.html',
  styleUrls: ['./wineries.component.css']
})
export class WineriesComponent implements AfterViewInit {
  displayedColumns: string[] = ['order', 'friendlyName', 'edit'];
  dataSource: MatTableDataSource<Project>;

  projectsCollectionRef: AngularFirestoreCollection<Project>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private afs: AngularFirestore) {
  }

  ngAfterViewInit() {
    this.projectsCollectionRef = this.afs.collection<Project>('projects', ref => {
      // Compose a query using multiple .where() methods
      return ref
        .where('type', '==', ProjectType.winery);
    });

    this.projectsCollectionRef.snapshotChanges().pipe(
      map((actions) => {
        let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as Project;
          if (!data.order) {
            data.order = initialOrder;
          }
          const id = a.payload.doc.id;
          initialOrder++;
          return {id, ...data};
        });
      })
    ).subscribe(data => {
      if (this.dataSource) {
        this.dataSource.data = data;
      } else {
        this.dataSource = new MatTableDataSource(data);
      }
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  trackByUid(index, item) {
    return item.uid;
  }

  orderUp(item: Project) {
    const sorted = this.dataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemAbove = sorted[index - 1];

    if (index > 0) {
      const itemDoc = this.afs.doc<Project>(`projects/${item.id}`);
      const itemAboveDoc = this.afs.doc<Project>(`projects/${itemAbove.id}`);
      itemDoc.update({order: item.order - 1});
      itemAboveDoc.update({order: itemAbove.order + 1});
    }
  }

  orderDown(item: Project) {
    const sorted = this.dataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemBelow = sorted[index + 1];

    if (index < this.dataSource.data.length) {
      const itemDoc = this.afs.doc<Project>(`projects/${item.id}`);
      const itemBelowDoc = this.afs.doc<Project>(`projects/${itemBelow.id}`);
      itemDoc.update({order: item.order + 1});
      itemBelowDoc.update({order: itemBelow.order - 1});
    }
  }
}
