import {Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {Project, ProjectType} from '../models/project.model';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {remove} from 'lodash';

@Component({
  selector: 'app-project-editor',
  templateUrl: './project-editor.component.html',
  styleUrls: ['./project-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectEditorComponent implements OnInit {
  @Input() projectRef: AngularFirestoreDocument<Project>;
  @Input() projectsCollectionRef: AngularFirestoreCollection<Project>;
  @Input() project: Project;

  @Output() saved = new EventEmitter<Project>();
  @Output() deleted = new EventEmitter<any>();

  constructor(
    private afs: AngularFirestore,
  ) {
    this.projectsCollectionRef = this.afs.collection<Project>('projects');
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnInit() {
  }

  addParagraph() {
    this.project.content.paragraphs.push('');
  }

  deleteParagraph(index) {
    this.project.content.paragraphs.splice(index, 1);
  }

  addSidebar() {
    this.project.content.sidebar.push({
      id: null,
      name: null,
      value: null
    });
  }

  deleteSidebar(index) {
    this.project.content.sidebar.splice(index, 1);
  }

  addProjectPhoto() {
    this.project.content.images.push({path: null, alt: null});
  }

  deleteProjectPhoto(index: number) {
    this.project.content.images.splice(index, 1);
  }

  addCollaborator() {
    this.project.content.collaborations.push({
      title: null,
      subtitle: null,
      text: null,
      image: {
        path: null,
        alt: null
      }
    });
  }

  deleteCollaborator(index: number) {
    this.project.content.collaborations.splice(index, 1);
  }

  saveProject() {
    delete this.project.id;
    this.project.content.title = this.project.friendlyName;
    remove(this.project.content.images, (image) => {
      return image.path === null && image.path === null;
    });
    remove(this.project.content.paragraphs, (paragraph) => {
      return paragraph === null || paragraph === '';
    });

    this.saved.emit(this.project);
  }

  deleteProject() {
    this.deleted.emit();
  }
}
