import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NewsArticle} from '../models/news-article.model';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements AfterViewInit {
  // Loaders
  loading = false;
  initialized = false;

  // Firebase Collection Reference
  careersPageCollectionRef: AngularFirestoreCollection<any>;

  // Hero Style Options
  styles = [
    'black', 'blue', 'deepBlue'
  ];

  // Careers Page Sections
  openPositions: any = [];
  halfAndHalf: any = null;
  hero: any = null;

  constructor(private afs: AngularFirestore) { }

  ngAfterViewInit() {
    this.loading = true;
    this.careersPageCollectionRef = this.afs.collection<any>('careers-page');

    this.careersPageCollectionRef.snapshotChanges().pipe(
      map((actions) => {
        // let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as any;
          if (data['open-positions']) {
            this.openPositions = data['open-positions'];
            this.openPositions.positions.map((position) => {
              return position.active = false;
            });
          } else if (data['half-and-half']) {
            this.halfAndHalf = data['half-and-half'];
          } else if (data.hero) {
            this.hero = data.hero;
          }
        }
        );
      })
    ).subscribe(data => {
      this.initialized = true;
      this.loading = false;
      console.log('data', this.openPositions, this.halfAndHalf, this.hero);
    });
  }

  // Save Hero Data
  onSaveHero(f: NgForm) {
    this.loading = true;
    console.log(f.value);
    const updateData = {
      hero: {
        title: f.value.heroTitle,
        subtitle: f.value.heroSubtitle,
        style: this.hero.style
      }
    };
    const careersPageHeroReference = this.careersPageCollectionRef.doc('hero');
    return careersPageHeroReference.update(updateData)
      .then(() => {
        this.loading = false;
      });
  }

  // Save Half & Half
  onSaveHalfAndHalf(f: NgForm) {
    this.loading = true;
    const updateData = {
      'half-and-half': {
        title: f.value.halfAndHalfTitle,
        content: f.value.halfAndHalfContent,
        ctaLink: f.value.halfAndHalfCtaLink,
        ctaText: f.value.halfAndHalfCtaText,
        image: f.value.halfAndHalfImage
      }
    };
    const careersPageHeroReference = this.careersPageCollectionRef.doc('half-and-half');
    return careersPageHeroReference.update(updateData)
      .then(() => {
        this.loading = false;
      });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  // Save Career Opportunities
  onSaveCareerOpportunities() {
    this.loading = true;
    const careerOpportunitiesData = {...this.openPositions};
    careerOpportunitiesData.positions.forEach((position, i) => {
      delete careerOpportunitiesData.positions[i].active
    });
    this.openPositions = careerOpportunitiesData;
    const request = {
      'open-positions': careerOpportunitiesData
    };
    const careersPageCareerOpportunitiesReference = this.careersPageCollectionRef.doc('career-opportunities');
    return careersPageCareerOpportunitiesReference.update(request)
      .then(() => {
        this.loading = false;
      });
  }

  onOrderPositionUp(e, position) {
    e.preventDefault();
    const sorted = this.openPositions.positions.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.title === position.title;
    });
    const jobPosition = sorted[index];
    if (jobPosition.order !== 1) {
      const jobPositionAbove = sorted[index - 1];
      jobPosition.order = jobPosition.order - 1;
      jobPositionAbove.order = jobPositionAbove.order + 1;
      sorted.splice(index, 1);
      sorted.splice(index, 0 , jobPositionAbove);
      sorted.splice(index - 1, 1);
      sorted.splice(index - 1, 0, jobPosition);
      this.openPositions.positions = sorted;
    }
  }

  onOrderPositionDown(e, position) {
    e.preventDefault();
    const sorted = this.openPositions.positions.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.title === position.title;
    });
    const jobPosition = sorted[index];
    if (jobPosition.order !== sorted.length) {
      const jobPositionBelow = sorted[index + 1];
      jobPosition.order = jobPosition.order + 1;
      jobPositionBelow.order = jobPositionBelow.order - 1;
      sorted.splice(index, 1);
      sorted.splice(index, 0 , jobPositionBelow);
      sorted.splice(index + 1, 1);
      sorted.splice(index + 1, 0, jobPosition);
      this.openPositions.positions = sorted;
    }
  };

  onDeleteCareerPosition(positionIndex) {
    const confirmation = window.confirm('Are you sure you would like to delete this position?');
    // If Admin Confirmed to Delete the Position, Continue with Deleting it.
    if (confirmation) {
      // Remove the Position from the Positions List
      this.openPositions.positions.splice(positionIndex, 1);
      // Set New Order for Open Position
      if (this.openPositions.positions.length > 0) {
        this.openPositions.positions.forEach((position, i) => {
          if (i >= positionIndex) {
            this.openPositions.positions[i].order -= 1;
          }
        });
      }
    }
  }

  onLocationChange(value, positionIndex, locationIndex) {
    this.openPositions.positions[positionIndex].locations[locationIndex] = value;
  }

  onAddPosition() {
    this.openPositions.positions.push({
      order: this.openPositions.positions.length + 1,
      title: "",
      open: false,
      description: "",
      locations: []
    });
  }

}
