import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../guards/auth-guard.guard';
import {NgModule} from '@angular/core';
import {MainComponent} from './main.component';
import {LandingComponent} from './landing/landing.component';
import {HomesComponent} from './homes/homes.component';
import {ResortsComponent} from './resorts/resorts.component';
import {WineriesComponent} from './wineries/wineries.component';
import {RestaurantsComponent} from './restaurants/restaurants.component';
import {HistoricComponent} from './historic/historic.component';
import {CommunityComponent} from './community/community.component';
import {OfficesComponent} from './offices/offices.component';
import {IndustrialComponent} from './industrial/industrial.component';
import {NewsComponent} from './news/news.component';
import {ArticleComponent} from './news/article.component';
import {HomeProjectComponent} from './homes/home-project.component';
import {ResortProjectComponent} from './resorts/resort-project.component';
import {WineryProjectComponent} from './wineries/winery-project.component';
import {RestaurantProjectComponent} from './restaurants/restaurant-project.component';
import {HistoricProjectComponent} from './historic/historic-project.component';
import {CommunityProjectComponent} from './community/community-project.component';
import {OfficeProjectComponent} from './offices/office-project.component';
import {IndustrialProjectComponent} from './industrial/industrial-project.component';
import {ExpertiseComponent} from './expertise/expertise.component';
import {ServiceComponent} from './expertise/service.component';
import {MemberComponent} from './expertise/member.component';
import {OtherMemberComponent} from './expertise/other-member.component';
import {CarouselImageComponent} from "./landing/carousel-image.component";
import {ProjectCardComponent} from "./landing/project-card.component";
import {CareersComponent} from './careers/careers.component';

const mainRoutes: Routes = [
  {
    path: 'home',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/home/landing',
        pathMatch: 'full'
      },
      {
        path: 'landing',
        children: [
          {
            path: '',
            component: LandingComponent
          },
          {
            path: 'carousel/:id',
            component: CarouselImageComponent
          },
          {
            path: 'project-card/:id',
            component: ProjectCardComponent
          }
        ]
      },
      {
        path: 'homes',
        children: [
          {
            path: '',
            component: HomesComponent
          },
          {
            path: ':id',
            component: HomeProjectComponent
          }
        ]
      },
      {
        path: 'resorts',
        children: [
          {
            path: '',
            component: ResortsComponent
          },
          {
            path: ':id',
            component: ResortProjectComponent
          }
        ]
      },
      {
        path: 'wineries',
        children: [
          {
            path: '',
            component: WineriesComponent
          },
          {
            path: ':id',
            component: WineryProjectComponent
          }
        ]
      },
      {
        path: 'restaurants',
        children: [
          {
            path: '',
            component: RestaurantsComponent
          },
          {
            path: ':id',
            component: RestaurantProjectComponent
          }
        ]
      },
      {
        path: 'historic',
        children: [
          {
            path: '',
            component: HistoricComponent
          },
          {
            path: ':id',
            component: HistoricProjectComponent
          }
        ]
      },
      {
        path: 'community',
        children: [
          {
            path: '',
            component: CommunityComponent
          },
          {
            path: ':id',
            component: CommunityProjectComponent
          }
        ]
      },
      {
        path: 'offices',
        children: [
          {
            path: '',
            component: OfficesComponent
          },
          {
            path: ':id',
            component: OfficeProjectComponent
          }
        ]
      },
      {
        path: 'industrial',
        children: [
          {
            path: '',
            component: IndustrialComponent
          },
          {
            path: ':id',
            component: IndustrialProjectComponent
          }
        ]
      },
      {
        path: 'news',
        children: [
          {
            path: '',
            component: NewsComponent
          },
          {
            path: ':id',
            component: ArticleComponent
          }
        ]
      },
      {
        path: 'careers',
        children: [
          {
            path: '',
            component: CareersComponent
          }
        ],
      },
      {
        path: 'expertise',
        children: [
          {
            path: '',
            component: ExpertiseComponent
          },
          {
            path: 'services/:id',
            component: ServiceComponent
          },
          {
            path: 'team/:id',
            component: MemberComponent
          },
          {
            path: 'other-team/:id',
            component: OtherMemberComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(mainRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule {
}


