import {Component, OnDestroy, OnInit} from '@angular/core';
import {NewsArticle} from '../models/news-article.model';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {firestore} from 'firebase';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit, OnDestroy {
  newsArticleRef: AngularFirestoreDocument<NewsArticle>;
  newsCollectionRef: AngularFirestoreCollection<NewsArticle>;
  newsArticle: NewsArticle;
  newsArticleDate: Date;

  private sub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.newsCollectionRef = this.afs.collection<NewsArticle>('news');
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params.id && params.id !== 'new') {
        this.newsArticleRef = this.newsCollectionRef.doc(params.id);

        this.newsArticleRef.get().subscribe((documentSnapshot: firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            this.newsArticle = {
              id: documentSnapshot.id,
              slug: data.slug,
              image: data.image,
              publication: data.publication,
              title: data.title,
              subtitle: data.subtitle,
              text: data.text,
              link: data.link,
              author: data.author,
              date: data.date,
              order: data.order || 1
            };
            this.newsArticleDate = this.newsArticle.date.toDate();
          } else {
            this.router.navigate(['/not-found']);
          }
        });
      } else {
        this.newsArticle = {
          id: null,
          slug: null,
          image: {
            url: null,
            alt: null
          },
          publication: null,
          title: null,
          subtitle: null,
          text: null,
          link: null,
          author: null,
          date: firestore.Timestamp.now(),
          order: 1
        };
        this.newsArticleDate = new Date();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteArticle() {
    const confirmDelete = confirm('This action is irreversible. Are you sure you want to delete the article?');
    if (confirmDelete) {
      this.newsCollectionRef.doc(this.newsArticle.id).delete()
        .then(() => {
          return this.router.navigate(['/home/news']);
        });
    }
  }

  saveArticle() {
    delete this.newsArticle.id;
    this.newsArticle.date = firestore.Timestamp.fromDate(this.newsArticleDate);

    if (!this.newsArticleRef) {
      this.newsCollectionRef.add(this.newsArticle)
        .then(() => {
          return this.router.navigate(['/home/news']);
        });
    } else {
      return this.newsArticleRef.update(this.newsArticle)
        .then(() => {
          return this.router.navigate(['/home/news']);
        });
    }
  }
}
