import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {firestore} from 'firebase';
import {LandingCarouselImage} from '../models/landing-carousel-image.model';

@Component({
  selector: 'app-carousel-image',
  templateUrl: './carousel-image.component.html',
  styleUrls: ['./carousel-image.component.css']
})
export class CarouselImageComponent implements OnInit, OnDestroy {
  carouselImageRef: AngularFirestoreDocument<LandingCarouselImage>;
  carouselImageCollectionRef: AngularFirestoreCollection<LandingCarouselImage>;
  carouselImage: LandingCarouselImage;

  private sub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.carouselImageCollectionRef = this.afs.collection<LandingCarouselImage>('carousel-images');
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params.id && params.id !== 'new') {
        this.carouselImageRef = this.carouselImageCollectionRef.doc(params.id);

        this.carouselImageRef.get().subscribe((documentSnapshot: firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            this.carouselImage = {
              id: documentSnapshot.id,
              url: data.url,
              alt: data.alt,
              order: data.order,
            };
          } else {
            this.router.navigate(['/not-found']);
          }
        });
      } else {
        this.carouselImage = {
          id: null,
          url: null,
          alt: null,
          order: 0
        };
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteCarouselImage() {
    const confirmDelete = confirm('This action is irreversible. Are you sure you want to delete the member?');
    if (confirmDelete) {
      this.carouselImageCollectionRef.doc(this.carouselImage.id).delete()
        .then(() => {
          return this.router.navigate(['/home/landing']);
        });
    }
  }

  saveCarouselImage() {
    delete this.carouselImage.id;

    if (!this.carouselImageRef) {
      this.carouselImageCollectionRef.add(this.carouselImage)
        .then(() => {
          return this.router.navigate(['/home/landing']);
        });
    } else {
      return this.carouselImageRef.update(this.carouselImage)
        .then(() => {
          return this.router.navigate(['/home/landing']);
        });
    }
  }
}
