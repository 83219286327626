import {NgModule} from '@angular/core';
import { MainComponent } from './main.component';
import {MainRoutingModule} from './main-routing.module';
import {
  MatButtonModule, MatCardModule, MatDatepickerModule,
  MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule,
  MatListModule, MatNativeDateModule, MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import { LandingComponent } from './landing/landing.component';
import { HomesComponent } from './homes/homes.component';
import { ResortsComponent } from './resorts/resorts.component';
import { WineriesComponent } from './wineries/wineries.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { HistoricComponent } from './historic/historic.component';
import { CommunityComponent } from './community/community.component';
import { OfficesComponent } from './offices/offices.component';
import { IndustrialComponent } from './industrial/industrial.component';
import { NewsComponent } from './news/news.component';
import {CommonModule} from '@angular/common';
import { ArticleComponent } from './news/article.component';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import { HomeProjectComponent } from './homes/home-project.component';
import { ResortProjectComponent } from './resorts/resort-project.component';
import { WineryProjectComponent } from './wineries/winery-project.component';
import { RestaurantProjectComponent } from './restaurants/restaurant-project.component';
import { HistoricProjectComponent } from './historic/historic-project.component';
import { CommunityProjectComponent } from './community/community-project.component';
import { OfficeProjectComponent } from './offices/office-project.component';
import { IndustrialProjectComponent } from './industrial/industrial-project.component';
import {DirectivesModule} from '../directives/directives.module';
import { ExpertiseComponent } from './expertise/expertise.component';
import { ServiceComponent } from './expertise/service.component';
import { MemberComponent } from './expertise/member.component';
import { OtherMemberComponent } from './expertise/other-member.component';
import {ProjectEditorComponent} from './project-editor/project-editor.component';
import { CarouselImageComponent } from './landing/carousel-image.component';
import { ProjectCardComponent } from './landing/project-card.component';
import { CareersComponent } from './careers/careers.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    imports: [
        DirectivesModule,
        FormsModule,
        BrowserModule,
        CommonModule,
        MatCardModule,
        MatGridListModule,
        MatIconModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatButtonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatInputModule,
        MatFormFieldModule,
        MatTableModule,
        MatSelectModule,
        MatSortModule,
        MainRoutingModule,
        MatCheckboxModule,
    ],
  declarations: [
    MainComponent,
    LandingComponent,
    HomesComponent,
    ResortsComponent,
    WineriesComponent,
    RestaurantsComponent,
    HistoricComponent,
    CommunityComponent,
    OfficesComponent,
    IndustrialComponent,
    NewsComponent,
    ArticleComponent,
    HomeProjectComponent,
    ResortProjectComponent,
    WineryProjectComponent,
    RestaurantProjectComponent,
    HistoricProjectComponent,
    CommunityProjectComponent,
    OfficeProjectComponent,
    IndustrialProjectComponent,
    ExpertiseComponent,
    ServiceComponent,
    MemberComponent,
    OtherMemberComponent,
    ProjectEditorComponent,
    CarouselImageComponent,
    ProjectCardComponent,
    CareersComponent
  ]
})

export class MainModule {
}
