import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {LandingCarouselImage} from '../models/landing-carousel-image.model';
import {LandingProject} from '../models/landing-project';
import {OtherTeam, Team} from "../models/team.model";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements AfterViewInit {
  displayedColumns: string[] = ['order', 'alt', 'edit'];
  displayedProjectColumns: string[] = ['order', 'name', 'edit'];

  dataSource: MatTableDataSource<LandingCarouselImage>;
  projectCardsSource: MatTableDataSource<LandingProject>;

  projectCardsRef: AngularFirestoreCollection<LandingProject>;
  carouselImagesRef: AngularFirestoreCollection<LandingCarouselImage>;

  @ViewChild('carouselSort', {static: true}) carouselSort: MatSort;
  @ViewChild('projectSort', {static: true}) projectSort: MatSort;

  constructor(private afs: AngularFirestore) {
  }

  ngAfterViewInit() {
    this.carouselImagesRef = this.afs.collection<LandingCarouselImage>('carousel-images');
    this.carouselImagesRef.snapshotChanges().pipe(
      map((actions) => {
        let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as LandingCarouselImage;
          if (!data.order) {
            data.order = initialOrder;
          }
          const id = a.payload.doc.id;
          initialOrder++;
          return {id, ...data};
        });
      })
    ).subscribe(data => {
      if (this.dataSource) {
        this.dataSource.data = data;
      } else {
        this.dataSource = new MatTableDataSource(data);
      }
      this.dataSource.sort = this.carouselSort;
    });

    this.projectCardsRef = this.afs.collection<LandingProject>('landing-projects');
    this.projectCardsRef.snapshotChanges().pipe(
      map((actions) => {
        let initialOrder = 1;
        return actions.map(a => {
          const data = a.payload.doc.data() as LandingProject;
          if (!data.order) {
            data.order = initialOrder;
          }
          const id = a.payload.doc.id;
          initialOrder++;
          return {id, ...data};
        });
      })
    ).subscribe(projectData => {
      if (this.projectCardsSource) {
        this.projectCardsSource.data = projectData;
      } else {
        this.projectCardsSource = new MatTableDataSource(projectData);
      }
      this.projectCardsSource.sort = this.projectSort;
    });
  }

  trackByUid(index, item) {
    return item.uid;
  }

  orderCarouselUp(item: Team) {
    const sorted = this.dataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemAbove = sorted[index - 1];

    if (index > 0) {
      const itemDoc = this.afs.doc<any>(`carousel-images/${item.id}`);
      const itemAboveDoc = this.afs.doc<any>(`carousel-images/${itemAbove.id}`);
      itemDoc.update({order: item.order - 1});
      itemAboveDoc.update({order: itemAbove.order + 1});
    }
  }

  orderCarouselDown(item: Team) {
    const sorted = this.dataSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemBelow = sorted[index + 1];

    if (index < this.dataSource.data.length) {
      const itemDoc = this.afs.doc<any>(`carousel-images/${item.id}`);
      const itemBelowDoc = this.afs.doc<any>(`carousel-images/${itemBelow.id}`);
      itemDoc.update({order: item.order + 1});
      itemBelowDoc.update({order: itemBelow.order - 1});
    }
  }

  orderProjectUp(item: OtherTeam) {
    const sorted = this.projectCardsSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemAbove = sorted[index - 1];

    if (index > 0) {
      const itemDoc = this.afs.doc<any>(`landing-projects/${item.id}`);
      const itemAboveDoc = this.afs.doc<any>(`landing-projects/${itemAbove.id}`);
      itemDoc.update({order: item.order - 1});
      itemAboveDoc.update({order: itemAbove.order + 1});
    }
  }

  orderProjectDown(item: OtherTeam) {
    const sorted = this.projectCardsSource.data.sort((a, b) => {
      return a.order - b.order;
    });
    const index = sorted.findIndex((a) => {
      return a.id === item.id;
    });
    const itemBelow = sorted[index + 1];

    if (index < this.projectCardsSource.data.length) {
      const itemDoc = this.afs.doc<any>(`landing-projects/${item.id}`);
      const itemBelowDoc = this.afs.doc<any>(`landing-projects/${itemBelow.id}`);
      itemDoc.update({order: item.order + 1});
      itemBelowDoc.update({order: itemBelow.order - 1});
    }
  }
}
