import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {firestore} from 'firebase';
import {Service} from '../models/service.model';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit, OnDestroy {
  serviceRef: AngularFirestoreDocument<Service>;
  serviceCollectionRef: AngularFirestoreCollection<Service>;
  service: Service;

  private sub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.serviceCollectionRef = this.afs.collection<Service>('services');
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params.id && params.id !== 'new') {
        this.serviceRef = this.serviceCollectionRef.doc(params.id);

        this.serviceRef.get().subscribe((documentSnapshot: firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            this.service = {
              id: documentSnapshot.id,
              description: data.description,
              icon: data.icon,
              link: data.link,
              title: data.title,
              order: data.order
            };
          } else {
            this.router.navigate(['/not-found']);
          }
        });
      } else {
        this.service = {
          id: null,
          description: null,
          icon: null,
          link: null,
          title: null,
          order: 0
        };
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteService() {
    const confirmDelete = confirm('This action is irreversible. Are you sure you want to delete the member?');
    if (confirmDelete) {
      this.serviceCollectionRef.doc(this.service.id).delete()
        .then(() => {
          return this.router.navigate(['/home/expertise']);
        });
    }
  }

  saveService() {
    delete this.service.id;

    if (!this.serviceRef) {
      this.serviceCollectionRef.add(this.service)
        .then(() => {
          return this.router.navigate(['/home/expertise']);
        });
    } else {
      return this.serviceRef.update(this.service)
        .then(() => {
          return this.router.navigate(['/home/expertise']);
        });
    }
  }
}

